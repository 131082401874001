<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onBeforeMount, onBeforeUnmount, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { RouterView, useRoute } from "vue-router";

import { datadogPlugin, socket } from "@/app/plugins";
import { useThemeStore } from "@/entities/theme";
import { ExitModal, useModalStore } from "@/features/modal";
import { ToastNotification, useNotificationStore } from "@/features/notification";
import { transitions } from "@/shared/constants";
import { bridgeService } from "@/shared/services";
import { Snackbar } from "@/shared/ui-v2";
import { Header, HeaderV2 } from "@/widgets/header";

defineOptions({
  name: "App",
});

const { t } = useI18n();
const route = useRoute();

const { isLegacyDesign, theme } = storeToRefs(useThemeStore());

const modalStore = useModalStore();
const { isOpen } = storeToRefs(modalStore);
const { changeIsOpen } = modalStore;

const notificationStore = useNotificationStore();
const { notifications } = storeToRefs(notificationStore);
const { removeNotification } = notificationStore;

const headerComponent = computed(() => (isLegacyDesign.value ? Header : HeaderV2));
const notificationComponent = computed(() => (isLegacyDesign.value ? ToastNotification : Snackbar));

const pageType = computed(() => route.meta.transaction ?? "default");

const translations = computed(() => ({
  default: {
    text: t("modal.text"),
    title: t("modal.title"),
  },
  deposit: {
    text: t("deposit.modal.text"),
    title: t("deposit.modal.title"),
  },
  withdrawal: {
    text: t("withdrawal.modal.text"),
    title: t("withdrawal.modal.title"),
  },
}));

const initTheme = () => {
  document.documentElement.classList.add(theme.value);
};

onBeforeMount(initTheme);

onMounted(async () => {
  if (window.__appStartTime) {
    datadogPlugin.sendCustomMetric("Time To First Render", {
      timeToFirstRender: performance.now() - window.__appStartTime,
    });
  }
});

onBeforeUnmount(socket.disconnect);
</script>

<template>
  <div class="bg-white text-gray-900 dark:bg-onyx dark:text-white">
    <component :is="headerComponent" />
    <main class="overflow-hidden">
      <RouterView />
    </main>
    <ExitModal
      :is-open="isOpen"
      :translations="translations[pageType]"
      @close="changeIsOpen(false)"
      @submit="bridgeService.notify({ messageType: 'onClose' })"
    />
    <TransitionGroup :name="transitions.slideDown">
      <component
        :is="notificationComponent"
        v-for="notification of notifications"
        :key="notification.id"
        :color="notification.color"
        :content="notification.content"
        :custom-class="notification.customClass"
        @close="removeNotification(notification.id)"
      />
    </TransitionGroup>
  </div>
</template>
