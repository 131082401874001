import { useVuelidate } from "@vuelidate/core";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import { i18n } from "@/app/plugins";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { useFormStore } from "@/features/form";
import { useNotificationStore } from "@/features/notification";
import type { RequestError } from "@/shared/api";

export const useValidation = () => {
  const { t } = useI18n();

  const { limits } = storeToRefs(usePaymentMethodStore());

  const formStore = useFormStore();
  const { amount, fields, errorRefs, isAmountValid, validationRules, validationState } = storeToRefs(formStore);
  const { changeAmountValidity, changeFormValidity, setErrorRefs, setFields, scrollToErrorRef } = formStore;

  const { createNotification } = useNotificationStore();

  const handleError = (error: RequestError) => {
    const httpCodeTranslationKey = `httpCodes.${error.data?.status}`;
    const httpCodeTranslation = t(httpCodeTranslationKey);
    // TODO С i18n.te(key) было бы красивее, но он возвращает ложно негативный результат, разобраться вне крита
    if (httpCodeTranslation !== httpCodeTranslationKey) {
      createNotification({ color: "danger", content: httpCodeTranslation });
      return;
    }

    return createNotification({ color: "danger", content: i18n.global.t("provider.error") });
  };

  const validate = async () => {
    const v$ = useVuelidate(validationRules.value, validationState.value);
    const isFormValid = await v$.value.$validate();

    changeAmountValidity(+amount.value >= limits.value.min && +amount.value <= limits.value.max);
    changeFormValidity(isFormValid);

    if (!isFormValid || !isAmountValid.value) {
      setFields(
        fields.value.map((field) => ({
          ...field,
          props: {
            ...field.props,
            validations: field.props.validations.map((validation) => ({
              ...validation,
              isExists: v$.value?.[field.name]?.$error || !field.props.value,
            })),
          },
        })),
      );
      setErrorRefs(
        errorRefs.value.map((item) => ({
          ...item,
          hasError:
            fields.value
              .find((field) => field.name === item.name)
              ?.props?.validations?.every((validation) => validation.isExists) ?? false,
        })),
      );
      scrollToErrorRef(errorRefs.value);
    }

    return isFormValid && isAmountValid.value;
  };

  return {
    handleError,
    validate,
  };
};
