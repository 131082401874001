import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";

import { usePaymentMethodStore } from "@/entities/payment-method";
import type { Transaction } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";
import { useFormStore } from "@/features/form";
import type { AnalyticsEvent } from "@/shared/services";
import { bridgeService } from "@/shared/services";

export const useAnalytics = () => {
  const route = useRoute();

  const { user, country } = storeToRefs(useUserStore());

  const { amount } = storeToRefs(useFormStore());

  const { paymentMethod } = storeToRefs(usePaymentMethodStore());

  const cardType = computed(() => {
    if (paymentMethod.value?.name.includes("visa")) {
      return "visa";
    }
    if (paymentMethod.value?.name.includes("mastercard")) {
      return "mastercard";
    }
    if (paymentMethod.value?.name.includes("mir")) {
      return "mir";
    }
    return undefined;
  });

  const baseDepositPayload = computed(
    (): Pick<AnalyticsEvent["payload"], "event" | "event_cat" | "deposit_method" | "currency"> => ({
      event: "send_deposit_events",
      event_cat: "deposit",
      deposit_method: paymentMethod.value?.name ?? "default",
      currency: user.value.currency,
    }),
  );

  const baseWithdrawalPayload = computed(
    (): Pick<
      AnalyticsEvent["payload"],
      "event" | "event_cat" | "withdrawal_method" | "currency" | "payment_region"
    > => ({
      event: "send_withdrawal_events",
      event_cat: "withdrawal",
      withdrawal_method: paymentMethod.value?.name ?? "default",
      currency: user.value.currency,
      payment_region: country.value,
    }),
  );

  const depositSubmitPayload = computed(() => ({
    event_name: "submit",
    card_type: cardType.value,
    submit_amount: amount.value,
    payment_region: country.value,
    form_type: "method",
    payment_method: "default",
    ...baseDepositPayload.value,
  }));

  const depositFormViewPayload = computed(() => ({
    event_name: "form_view",
    payment_region: country.value,
    payment_method: paymentMethod.value?.name ?? "default",
    ...baseDepositPayload.value,
  }));

  const depositSelectFieldPayload = computed(() => ({
    event_name: "select_field",
    payment_region: country.value,
    ...baseDepositPayload.value,
  }));

  const depositInputFieldPayload = computed(() => ({
    event_name: "input_field",
    payment_region: country.value,
    card_type: cardType.value,
    ...baseDepositPayload.value,
  }));

  const depositButtonPayload = computed(() => ({
    event_name: "button",
    card_type: cardType.value,
    payment_region: country.value,
    ...baseDepositPayload.value,
  }));

  const depositExitPayload = computed(() => ({
    event_name: "exit",
    payment_region: country.value,
    form_type: "method",
    ...baseDepositPayload.value,
  }));

  const depositStatusPayload = computed(() => ({
    event_name: "status",
    card_type: cardType.value,
    submit_amount: amount.value,
    payment_region: country.value,
    ...baseDepositPayload.value,
  }));

  const depositErrorPayload = computed(() => ({
    event_name: "error",
    card_type: cardType.value,
    submit_amount: amount.value,
    payment_region: country.value,
    form_type: "method",
    ...baseDepositPayload.value,
  }));

  const withdrawalSubmitPayload = computed(() => ({
    event_name: "submit",
    card_type: cardType.value,
    submit_amount: amount.value,
    form_type: "method",
    payment_method: "default",
    ...baseWithdrawalPayload.value,
  }));

  const withdrawalFormViewPayload = computed(() => ({
    event_name: "form_view",
    ...baseWithdrawalPayload.value,
  }));

  const withdrawalExitPayload = computed(() => ({
    event_name: "exit",
    ...baseWithdrawalPayload.value,
  }));

  const withdrawalInputPayload = computed(() => ({
    event_name: "input_field",
    card_type: cardType.value,
    ...baseWithdrawalPayload.value,
  }));

  const withdrawalButtonPayload = computed(() => ({
    event_name: "button",
    card_type: cardType.value,
    submit_amount: amount.value,
    ...baseWithdrawalPayload.value,
  }));

  const withdrawalErrorPayload = computed(() => ({
    event_name: "error",
    card_type: cardType.value,
    submit_amount: amount.value,
    ...baseWithdrawalPayload.value,
  }));

  const sendEvent = (payload: AnalyticsEvent["payload"]) => {
    bridgeService.notify({ messageType: "onAnalyticsEvent", payload });
  };

  const sendDepositSubmitEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...depositSubmitPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendDepositFormViewEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...depositFormViewPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendDepositSelectFieldEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...depositSelectFieldPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendDepositInputFieldEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...depositInputFieldPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendDepositButtonEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...depositButtonPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendDepositExitEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...depositExitPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendDepositStatusEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...depositStatusPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendDepositErrorEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...depositErrorPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendWithdrawalSubmitEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...withdrawalSubmitPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendWithdrawalFormViewEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...withdrawalFormViewPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendWithdrawalExitEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...withdrawalExitPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendWithdrawalInputFieldEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...withdrawalInputPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendWithdrawalButtonEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...withdrawalButtonPayload.value,
      ...(payload ?? {}),
    });
  };

  const sendWithdrawalErrorEvent = (payload?: Record<string, unknown>) => {
    sendEvent({
      ...withdrawalErrorPayload.value,
      ...(payload ?? {}),
    });
  };

  const handleTransactionEvent = (handlers: Record<Transaction, () => void>) => {
    const transaction = route.meta?.transaction;
    if (!transaction || !handlers[transaction]) {
      return;
    }

    handlers[transaction]();
  };

  const sendButtonEvent = (payload?: Record<string, unknown>) => {
    handleTransactionEvent({
      deposit: () => sendDepositButtonEvent(payload),
      withdrawal: () => sendWithdrawalButtonEvent(payload),
    });
  };

  const transactionHandler = computed(() => {
    const handlers: Record<Transaction, () => void> = {
      deposit: () => sendDepositExitEvent(),
      withdrawal: () => sendWithdrawalExitEvent(),
    };

    const transaction = route.path.includes("withdrawal")
      ? "withdrawal"
      : route.path.includes("deposit")
      ? "deposit"
      : undefined;

    if (!transaction || !handlers[transaction]) {
      return;
    }

    return handlers[transaction];
  });

  const sendExitEvent = () => {
    const handler = transactionHandler.value;
    if (handler) {
      handler();
    }
  };

  const sendInputFieldEvent = (payload?: Record<string, unknown>) => {
    handleTransactionEvent({
      deposit: () => sendDepositInputFieldEvent(payload),
      withdrawal: () => sendWithdrawalInputFieldEvent(payload),
    });
  };

  const sendErrorEvent = (payload?: Record<string, unknown>) => {
    handleTransactionEvent({
      deposit: () => sendDepositErrorEvent(payload),
      withdrawal: () => sendWithdrawalErrorEvent(payload),
    });
  };

  const sendSubmitEvent = (payload?: Record<string, unknown>) => {
    handleTransactionEvent({
      deposit: () => sendDepositSubmitEvent(payload),
      withdrawal: () => sendWithdrawalSubmitEvent(payload),
    });
  };

  return {
    sendDepositSubmitEvent,
    sendDepositFormViewEvent,
    sendDepositSelectFieldEvent,
    sendDepositInputFieldEvent,
    sendDepositButtonEvent,
    sendDepositExitEvent,
    sendDepositStatusEvent,
    sendWithdrawalFormViewEvent,
    sendWithdrawalExitEvent,
    sendWithdrawalInputFieldEvent,
    sendWithdrawalButtonEvent,
    sendButtonEvent,
    sendExitEvent,
    sendInputFieldEvent,
    sendErrorEvent,
    sendSubmitEvent,
    sendWithdrawalSubmitEvent,
    sendWithdrawalErrorEvent,
  };
};
