import { nanoid } from "nanoid";
import { storeToRefs } from "pinia";

import { datadogPlugin, socket } from "@/app/plugins";
import { useDepositStore } from "@/entities/deposit";
import { transactionStatusValues } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";
import { useWithdrawalStore } from "@/entities/withdrawal";
import type { Endpoint, Request, Response } from "@/shared/api";
import { bridgeService, loggerService } from "@/shared/services";

type ResponseType = "depositStatus" | "token" | "withdrawalStatus";

const responseTypes: Readonly<Record<ResponseType, string>> = {
  depositStatus: "deposit.status",
  token: "login.token",
  withdrawalStatus: "withdrawal.status",
};

class ApiClient {
  requests: Dictionary<Request<unknown>> = {};

  constructor() {
    socket.on("a", (response: Response) => this.handleMessage(response));
  }

  private handleDepositStatusResponse(response: Response) {
    const { changeTransactionStatus } = useDepositStore();
    changeTransactionStatus(transactionStatusValues[response.data.status]);
  }

  private handleTokenResponse(response: Response) {
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const { changeUser } = userStore;
    changeUser({
      ...response.data,
      ...user.value,
    });

    datadogPlugin.setDatadogUser((response.data as unknown as { id_user: number }).id_user);
  }

  private handleWithdrawalStatusResponse(response: Response) {
    const { changeTransactionStatus } = useWithdrawalStore();
    changeTransactionStatus(transactionStatusValues[response.data.status]);
  }

  private handleResponse(response: Response) {
    switch (response.type) {
      case responseTypes.depositStatus:
        this.handleDepositStatusResponse(response);
        break;
      case responseTypes.token:
        this.handleTokenResponse(response);
        break;
      case responseTypes.withdrawalStatus:
        this.handleWithdrawalStatusResponse(response);
        break;
      default:
        break;
    }
  }

  private handleMessage(response: Response) {
    if (!response.msgid) {
      loggerService.log(">>> Server.push", response.type, response);
      this.handleResponse(response);
      return;
    }

    const request = this.requests[response.msgid];

    try {
      const data = {
        ...response,
        data: response.needParse ? JSON.parse(response.data as unknown as JSONString) : response.data,
      };

      request?.resolve?.(this.handleData(data));
    } catch (error) {
      request?.reject?.(error as Error);
    } finally {
      delete this.requests[response.msgid];
    }
  }

  private handleData(response: Response) {
    const nestedData =
      typeof response.data.data === "object"
        ? response.data.data
        : {
            status: null,
            message: null,
          };

    const statusResult = nestedData?.status ?? response.data.status;
    const messageResult = nestedData?.message ?? response.data.message;
    const responseResult = {
      ...response,
      data: {
        ...response.data,
        message: response.data.message ?? messageResult,
      },
    };

    loggerService.log("<<< Server.response", responseResult.source, responseResult);

    if (statusResult < 100 || (statusResult >= 200 && statusResult < 300) || statusResult > 600) {
      return Promise.resolve(responseResult.data);
    }

    if (statusResult === 401) {
      bridgeService.notify({ messageType: "onTokenExpired" });
    }

    bridgeService.notify({
      message: responseResult.data?.data?.message ?? responseResult.data.message,
      messageType: "Error",
      status: responseResult.data?.data?.status ?? responseResult.data.status,
      url: responseResult.source,
    });

    return Promise.reject(responseResult.data);
  }

  private changeRequests<T>(payload: T) {
    this.requests = { ...this.requests, ...payload };
  }

  async send<T, D>(action: Endpoint, data: D) {
    const request: Request<D> = {
      data,
      msgid: nanoid(),
      sendTime: Date.now(),
      type: action,
    };

    socket.emit("d", request);

    loggerService.log(">>> Server.request", action, request);

    return new Promise<{ data: T }>((resolve, reject) =>
      this.changeRequests({
        [request.msgid]: {
          ...request,
          resolve,
          reject,
        },
      }),
    );
  }
}

export const client = new ApiClient();
