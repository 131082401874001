import type { ParentData } from "@/entities/parent";
import type { User } from "@/entities/user";
import { loggerService } from "@/shared/services";

type Listener = "onGoBack" | "onSwipeBack";

type Payload =
  | AnalyticsEvent
  | CloseEvent
  | CopyEvent
  | CreateTransactionEvent
  | ErrorEvent
  | ExternalBrowserEvent
  | ExternalNavigationEvent
  | FullLoadedEvent
  | LoadedEvent
  | OpenRuleEvent
  | ShareEvent
  | TokenExpireEvent;

export interface AnalyticsEvent {
  messageType: "onAnalyticsEvent";
  payload: {
    event: "send_deposit_events" | "send_withdrawal_events";
    event_cat: "deposit" | "withdrawal";
    event_name: string;
  } & Record<string, unknown>;
}

interface CloseEvent {
  messageType: "onClose";
}

interface CopyEvent {
  copiedMessage: string;
  messageType: "onCopyToBuffer";
}

interface CreateTransactionEvent {
  eventType: "deposit" | "withdrawal";
  messageType: "onCreateTransaction";
  payload: {
    eventId: number;
  };
}

interface ErrorEvent {
  message: string;
  messageType: "Error";
  status: number;
  url: string;
}

interface ExternalBrowserEvent {
  messageType: "openExtBrowser";
  url: string;
}

interface ExternalNavigationEvent {
  messageType: "extNavigation";
  uri: string;
}

interface FullLoadedEvent {
  messageType: "FullLoaded";
  version: string;
}

interface LoadedEvent {
  messageType: "Loaded";
  version: string;
}

interface OpenRuleEvent {
  messageType: "openRule";
  ruleId: number;
}

interface ShareEvent {
  messageType: "onShare";
  sharedMessage: string;
}

interface TokenExpireEvent {
  messageType: "onTokenExpired";
}

class BridgeService {
  attachEventListener(type: Listener, listener: EventListenerOrEventListenerObject) {
    window.addEventListener(type, listener);
  }

  detachEventListener(type: Listener, listener: EventListenerOrEventListenerObject) {
    window.removeEventListener(type, listener);
  }

  notify(payload: Payload) {
    const data = JSON.stringify(payload);

    loggerService.log(">>> Bridge.notify", payload);

    if ("webkit" in window && "messageHandlers" in window.webkit && "message" in window.webkit.messageHandlers) {
      window.webkit.messageHandlers.message.postMessage(data);
      return;
    }

    if (window.billingBridge) {
      window.billingBridge?.nativePostMessage?.(data);
      return;
    }

    if (window.self !== window.top) {
      parent.postMessage(data, "*");
      return;
    }
  }

  isWebView(): boolean {
    return !!(
      window.billingBridge ||
      ("webkit" in window && "messageHandlers" in window.webkit && "message" in window.webkit.messageHandlers)
    );
  }

  loadFromWebView(): Promise<User> {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if ("billingUserData" in window) {
          clearInterval(interval);
          clearTimeout(timeout);
          resolve(window["billingUserData"] as unknown as User);
        }
      }, 10);

      const timeout = setTimeout(() => {
        clearInterval(interval);
        reject("Can't find a property in window");
      }, 5_000);

      this.notify({ messageType: "Loaded", version: import.meta.env.VITE_BUILD_VERSION || "1.0.0" });
    });
  }

  isIframe(): boolean {
    return window.self !== window.top;
  }

  loadFromIframe(): Promise<{
    parent: Pick<ParentData["payload"], "bonusId" | "closingButton" | "experiments" | "host" | "token" | "xOrigin">;
    user: Pick<User, "currency" | "paymentGeo" | "platform" | "lang">;
  }> {
    return new Promise((resolve) => {
      window.addEventListener("message", (event) => {
        let data;

        try {
          data = JSON.parse(event.data);
        } catch (e) {
          return;
        }

        if (data?.type === "loaded") {
          loggerService.log("<<< Bridge.loaded", data);

          resolve({
            parent: {
              bonusId: data?.payload?.bonusId ?? undefined,
              closingButton: data?.payload?.closingButton ?? true,
              experiments: data?.payload?.experiments ?? [],
              host: data?.payload?.host ?? "",
              token: data?.payload?.token ?? "",
              xOrigin: data?.payload?.xOrigin ?? "",
            },
            user: {
              currency: data?.payload?.currency,
              paymentGeo: "RU", // TODO заменить на `data?.payload?.paymentGeo` при релизе на другие страны, кроме РФ
              platform: data?.payload?.platform,
              lang: new Intl.Locale(data?.payload?.locale ?? "en-US").language as User["lang"],
            },
          });
        }
      });

      this.notify({ messageType: "Loaded", version: import.meta.env.VITE_BUILD_VERSION || "1.0.0" });
    });
  }
}

export const bridgeService = new BridgeService();
