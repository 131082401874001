import omit from "lodash/omit";
import toString from "lodash/toString";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import { datadogPlugin } from "@/app/plugins";
import type {
  AdditionalDepositPayload,
  Deposit,
  DepositPayload,
  HostToHostApiResponse,
  PrepareDepositDetail,
} from "@/entities/deposit";
import {
  createDeposit as createDepositRequest,
  prepareDeposit as prepareDepositRequest,
  depositApiResponseTypes,
  depositCustomPageIds,
} from "@/entities/deposit";
import { useParentStore } from "@/entities/parent";
import type { AdditionalField } from "@/entities/payment-method";
import { usePaymentMethodStore } from "@/entities/payment-method";
import type { TransactionStatusKey } from "@/entities/transaction";
import { transactionStatuses, openBrowser } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";
import { useAnalytics } from "@/features/analytics";
import { useFormStore, useValidation } from "@/features/form";
import type { RequestError } from "@/shared/api";
import { useRequest } from "@/shared/composables";
import { routeNames, routeNamesV2 } from "@/shared/constants";
import { bridgeService } from "@/shared/services";
import { isQrDetail } from "@/widgets/india";

const NAMESPACE = "deposit";

export const useDepositStore = defineStore(NAMESPACE, () => {
  const router = useRouter();

  const { parentData } = storeToRefs(useParentStore());

  const { isBankTransfer, paymentMethod } = storeToRefs(usePaymentMethodStore());

  const { user, isNativePlatform } = storeToRefs(useUserStore());

  const formStore = useFormStore();
  const { amount, fields, validationState } = storeToRefs(formStore);
  const { changeFields, mapAdditionalFields, mapFields, mapPreparationFields, setFields } = formStore;
  const { sendErrorEvent } = useAnalytics();

  const { handleError, validate } = useValidation();

  const {
    isLoading: isLoadingDeposit,
    data: depositData,
    execute: createDeposit,
  } = useRequest((payload: AdditionalDepositPayload) =>
    createDepositRequest({
      amount: +amount.value,
      currency: user.value.currency,
      depositId: depositId.value,
      fields: omit(validationState.value, "customWallet"),
      isActivatedBonus: Boolean(parentData.value.bonusId),
      paymentType: paymentMethod.value!.name,
      returnUrl: parentData.value.host || import.meta.env.VITE_RETURN_URL,
      wallet: toString(validationState.value?.customWallet),
      ...(parentData.value.bonusId ? { bonusId: parentData.value.bonusId } : undefined),
      ...payload,
    }),
  );

  const {
    isLoading: isLoadingPrepareDeposit,
    data: prepareDepositData,
    execute: prepareDeposit,
  } = useRequest((payload) =>
    prepareDepositRequest({
      paymentMethod: paymentMethod.value!.name,
      ...payload,
    }),
  );

  const apiResponse = ref<Deposit["apiResponse"]>();
  const depositId = ref<Deposit["depositId"]>();
  const transactionStatus = ref<TransactionStatusKey>(transactionStatuses.waiting);

  const details = computed<PrepareDepositDetail[]>(() =>
    (prepareDepositData.value?.details ?? []).filter((detail) => !isQrDetail(detail)),
  );
  const qrDetail = computed<PrepareDepositDetail | undefined>(() =>
    (prepareDepositData.value?.details ?? []).find(isQrDetail),
  );

  const changeApiResponse = (data: Deposit["apiResponse"] | undefined) => {
    apiResponse.value = data;
  };

  const changeDepositId = (value: number | undefined) => {
    depositId.value = value;
  };

  const changeTransactionStatus = (status: TransactionStatusKey) => {
    transactionStatus.value = status;
  };

  const handleDeposit = async (
    payload: Partial<AdditionalDepositPayload & DepositPayload>,
    routeName: Nullable<string>,
    isNeedToOpenNewWindow: boolean,
  ) => {
    const newWindow = isNativePlatform.value || !isNeedToOpenNewWindow ? null : window.open("/form");

    try {
      const startTime = performance.now();

      await createDeposit(payload);

      datadogPlugin.sendCustomMetric("Duration DepositCreate", {
        durationDepositCreate: performance.now() - startTime,
      });

      await handleResponse(depositData.value as Deposit, routeName, newWindow);
    } catch (e) {
      if (newWindow) {
        newWindow.close();
      }

      handleError(e as RequestError);

      sendErrorEvent({ error_text: (e as RequestError)?.message });
    }
  };

  const handleResponse = async (response: Deposit, routeName: Nullable<string>, window: Nullable<Window>) => {
    const { apiResponse, depositId, fields: additionalFields } = response;

    changeApiResponse(apiResponse);
    changeDepositId(apiResponse.depositId);

    if (apiResponse?.isFieldsRequired) {
      setFields([...fields.value, ...mapFields(mapAdditionalFields(additionalFields as AdditionalField[]))]);
      return;
    }

    if (apiResponse?.source) {
      openBrowser(apiResponse, window);
    }

    bridgeService.notify({
      eventType: "deposit",
      messageType: "onCreateTransaction",
      payload: {
        eventId: depositId,
      },
    });

    if (
      (apiResponse.data as HostToHostApiResponse)?.card ||
      (apiResponse.data as HostToHostApiResponse)?.requisites ||
      isBankTransfer.value
    ) {
      await router.push({ name: routeNamesV2.depositHostToHostPage });
      return;
    }

    if (
      apiResponse.method === depositApiResponseTypes.customPage &&
      apiResponse.pageId === depositCustomPageIds.upiQr
    ) {
      await router.push({ name: routeNames.depositCustomUpiQrPage });
      return;
    }

    if (routeName) {
      await router.push({ name: routeName });
    }
  };

  const handlePrepareDeposit = async (routeName: string) => {
    try {
      const startTime = performance.now();

      await prepareDeposit();

      datadogPlugin.sendCustomMetric("Duration DepositPrepare", {
        durationDepositPrepare: performance.now() - startTime,
      });

      await router.push({ name: routeName });
      changeFields(mapPreparationFields(prepareDepositData.value?.fields ?? []));
    } catch (e) {
      handleError(e as RequestError);
    }
  };

  const onPrepare = async (routeName: string) => {
    const isValid = await validate();

    if (!isValid) {
      return;
    }

    await handlePrepareDeposit(routeName);
  };

  const onSubmit = async (
    payload: Partial<AdditionalDepositPayload & DepositPayload> = {},
    routeName: Nullable<string> = routeNames.depositCompletePage,
    isNeedToOpenNewWindow = false,
  ) => {
    const isValid = await validate();

    if (!isValid) {
      return;
    }

    await handleDeposit(payload, routeName, isNeedToOpenNewWindow);
  };

  return {
    apiResponse,
    transactionStatus,
    details,
    qrDetail,
    isLoadingDeposit,
    isLoadingPrepareDeposit,
    changeApiResponse,
    changeTransactionStatus,
    onPrepare,
    onSubmit,
  };
});
