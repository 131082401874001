import type { User } from "@/entities/user";

type StorageKey = "forceLogging" | "user";

export type LoggingOption = "0" | "1";

const keys: Readonly<Record<StorageKey, string>> = {
  forceLogging: "force_logging",
  user: "user",
};

class AppStorage {
  private storage = sessionStorage;

  getForceLogging() {
    return (this.storage.getItem(keys.forceLogging) as LoggingOption) ?? "0";
  }

  setForceLogging(value: LoggingOption) {
    this.storage.setItem(keys.forceLogging, String(value));
  }

  getUser() {
    const data = this.storage.getItem(keys.user);

    if (!data) {
      return null;
    }

    return JSON.parse(data);
  }

  setUser(user: User) {
    this.storage.setItem(keys.user, JSON.stringify(user));
  }
}

export const appStorage = new AppStorage();
