import pick from "lodash/pick";
import { defineStore, storeToRefs } from "pinia";

import type { ParentData } from "@/entities/parent";
import { useParentStore } from "@/entities/parent";
import { useThemeStore } from "@/entities/theme";
import type { User } from "@/entities/user";
import { useUserStore } from "@/entities/user";
import { isParent, safeJsonParse } from "@/shared/lib";
import { appStorage } from "@/shared/services";

const NAMESPACE = "app";

export const useAppStore = defineStore(NAMESPACE, () => {
  const { changeParentData } = useParentStore();

  const { isLegacyDesign } = storeToRefs(useThemeStore());

  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);
  const { changeUser } = userStore;

  // TODO удалить код, он больше не используется
  const init = async () => {
    if (
      window.billingBridge ||
      ("webkit" in window && "messageHandlers" in window.webkit && "message" in window.webkit.messageHandlers)
    ) {
      await waitForProperty("billingUserData", 5_000, changeUser);
      return;
    }
    await waitForData();
    changeUser({
      ...user.value,
      ...(appStorage.getUser() ?? {}),
    });
  };

  const initStyles = async () => {
    if (isLegacyDesign.value) {
      await import("@/app/styles/legacy/index.css");
      return;
    }

    const stylesByPlatform = {
      android: async () => await import("@/app/styles/tokens/android/index.css"),
      desktop: async () => await import("@/app/styles/tokens/desktop/index.css"),
      ios: async () => await import("@/app/styles/tokens/ios/index.css"),
      mobile: async () => await import("@/app/styles/tokens/mobile/index.css"),
    };

    await Promise.allSettled([
      import("@/app/styles/base/index.css"),
      import("@/app/styles/tokens/common/index.css"),
      stylesByPlatform[user.value.platform](),
    ]);
  };

  const waitForData = async (): Promise<ParentData | void> =>
    new Promise((resolve) => {
      window.addEventListener("message", (event) => {
        const [, data] = safeJsonParse(event.data);

        if (data?.type === "loaded") {
          const { language } = new Intl.Locale(data?.payload?.locale ?? "en-US");

          changeParentData("bonusId", data?.payload?.bonusId ?? undefined);
          changeParentData("closingButton", data?.payload?.closingButton ?? true);
          changeParentData("experiments", data?.payload?.experiments ?? []);
          changeParentData("host", data?.payload?.host ?? "");
          changeParentData("token", data?.payload?.token ?? "");
          changeParentData("xOrigin", data?.payload?.xOrigin ?? "");

          changeUser({
            ...user.value,
            ...pick(data?.payload ?? [], ["currency", "paymentGeo", "platform"]),
            lang: language as User["lang"],
            ...(isParent && { paymentGeo: "ru" }),
          });
        }

        resolve();
      });
    });

  const waitForProperty = <T>(
    property: "billingUserData",
    timeout: number,
    callback: (value: T) => void,
  ): Promise<void> => {
    const INTERVAL_TIME = 250;

    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (property in window) {
          clearInterval(interval);
          callback(window[property] as T);
          resolve();
        }
      }, INTERVAL_TIME);

      setTimeout(() => {
        clearInterval(interval);
        reject("Can't find a property in window");
      }, timeout);
    });
  };

  return {
    init,
    initStyles,
  };
});
