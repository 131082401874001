import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import { datadogPlugin } from "@/app/plugins";
import { useParentStore } from "@/entities/parent";
import type { AdditionalField } from "@/entities/payment-method";
import { usePaymentMethodStore } from "@/entities/payment-method";
import {
  depositCancelStatuses,
  defaultResponse,
  paymentMethodsWithCancelButton,
  paymentMethodsWithGuarantee,
  paymentMethodsWithSupport,
  cancelDeposit as cancelDepositRequest,
  completeDeposit as completeDepositRequest,
  checkDeposit as checkDepositRequest,
  createDeposit as createDepositRequest,
  fetchBank as fetchBankRequest,
  fetchDepositStatus as fetchDepositStatusRequest,
  getBankBin,
} from "@/entities/peer-to-peer";
import type {
  DepositCompleteAdditionalPayload,
  DepositCreateResponse,
  PeerToPeerBank,
  DepositCheckResponse,
  BankPayload,
} from "@/entities/peer-to-peer";
import { useUserStore } from "@/entities/user";
import { useFormStore, useValidation } from "@/features/form";
import type { RequestError } from "@/shared/api";
import { useRequest } from "@/shared/composables";
import { routeNamesV2 } from "@/shared/constants";
import { fetchCaptchaV2, fetchCaptchaV3 } from "@/shared/lib";

const NAMESPACE = "peer-to-peer";

export const usePeerToPeerStore = defineStore(NAMESPACE, () => {
  const router = useRouter();

  const { paymentMethod, isPaymentMethodWithV3Recaptcha } = storeToRefs(usePaymentMethodStore());

  const { parentData } = storeToRefs(useParentStore());

  const { user } = storeToRefs(useUserStore());

  const formStore = useFormStore();
  const { amount, fields, validationState } = storeToRefs(formStore);
  const { mapAdditionalFields, mapFields, setFields } = formStore;

  const { handleError, validate } = useValidation();

  const {
    data: bankData,
    isLoading: isFetchingBank,
    execute: fetchBank,
  } = useRequest(async (payload) =>
    fetchBankRequest({
      payment_method: paymentMethod.value!.name,
      ...(payload as Pick<BankPayload, "bin">),
    }),
  );

  const {
    data: captchaData,
    isLoading: isFetchingCaptcha,
    execute: fetchCaptcha,
  } = useRequest(() => {
    if (isPaymentMethodWithV3Recaptcha.value) {
      return fetchCaptchaV3({ action: "p2pUserScore" });
    }

    return fetchCaptchaV2({});
  });

  const { data: depositStatusData, execute: fetchDepositStatus } = useRequest((payload) =>
    fetchDepositStatusRequest({
      depositId: response.value.depositId as number,
      paymentMethod: paymentMethod.value!.name,
      ...payload,
    }),
  );

  const {
    data: depositCompleteData,
    isLoading: isCancellingDeposit,
    execute: cancelDeposit,
  } = useRequest((payload) =>
    cancelDepositRequest({
      depositId: response.value.depositId as number,
      paymentMethod: paymentMethod.value!.name,
      ...payload,
    }),
  );

  const {
    data: checkDepositData,
    isLoading: isCheckingDeposit,
    execute: checkDeposit,
  } = useRequest((payload) =>
    checkDepositRequest({
      paymentMethod: paymentMethod.value!.name,
      ...payload,
    }),
  );

  const { isLoading: isCompletingDeposit, execute: completeDeposit } = useRequest((payload) =>
    completeDepositRequest({
      depositId: response.value.depositId as number,
      paymentMethod: paymentMethod.value!.name,
      ...payload,
    }),
  );

  const {
    data: depositData,
    isLoading: isCreatingDeposit,
    execute: createDeposit,
  } = useRequest((payload) =>
    createDepositRequest({
      amount: +amount.value,
      bank: paymentMethod.value!.name,
      bonusId: parentData.value.bonusId,
      checkScore: isPaymentMethodWithV3Recaptcha.value,
      currency: user.value.currency,
      depositId: response.value.depositId,
      fields: validationState.value,
      paymentMethod: paymentMethod.value!.name,
      isActivatedBonus: Boolean(parentData.value.bonusId),
      ...payload,
    }),
  );

  const bank = ref<PeerToPeerBank>();
  const isProcessingDeposit = ref(false);
  const response = ref<DepositCreateResponse>(defaultResponse);

  const hasCancelButton = computed(() => paymentMethodsWithCancelButton.includes(paymentMethod.value?.name ?? ""));

  const hasSupportButton = computed(() => paymentMethodsWithSupport.includes(paymentMethod.value?.name ?? ""));

  const isDepositCancelled = computed(() => {
    if (!depositStatusData.value?.status) {
      return false;
    }

    return depositCancelStatuses.includes(depositStatusData.value.status);
  });

  const isDepositExists = computed(() => Boolean(checkDepositData.value?.exists));

  const isGuaranteed = computed(() => paymentMethodsWithGuarantee.includes(paymentMethod.value?.name ?? ""));

  const changeBank = (data: PeerToPeerBank) => {
    bank.value = data;
  };

  const changeProcessingDeposit = (value: boolean) => {
    isProcessingDeposit.value = value;
  };

  const changeResponse = (data: DepositCreateResponse) => {
    response.value = { ...defaultResponse, ...data };
  };

  const handleFetchBank = async (payload: Pick<BankPayload, "bin">) => {
    try {
      await fetchBank(payload);
      changeBank(bankData.value as PeerToPeerBank);
    } catch (e) {
      throw new Error((e as Error).message);
    }
  };

  const handleFetchDepositStatus = async () => {
    try {
      await fetchDepositStatus();
    } catch (e) {
      // Always triggered but with correct status, so left it empty
    }
  };

  const handleCancelDeposit = async () => {
    try {
      await cancelDeposit();
    } catch (e) {
      throw new Error((e as Error).message);
    }
  };

  const handleCheckDeposit = async () => {
    try {
      await checkDeposit();

      const data = checkDepositData.value as DepositCheckResponse;

      changeResponse({ ...response.value, depositId: data.depositId });

      if (isDepositExists.value) {
        const bin = await getBankBin(data.card);

        if (bin) {
          await handleFetchBank({ bin });
        }

        changeResponse(data);

        await router.push({ name: routeNamesV2.depositPeerToPeerInvoicePage });
      }
    } catch (e) {
      throw new Error((e as Error).message);
    }
  };

  const handleCompleteDeposit = async (payload: DepositCompleteAdditionalPayload = {}) => {
    try {
      await completeDeposit(payload);
    } catch (e) {
      throw new Error((e as Error).message);
    } finally {
      await router.push({ name: routeNamesV2.depositCompletePage });
    }
  };

  const handleCreateDeposit = async () => {
    const isValid = await validate();

    if (!isValid) {
      return;
    }

    changeProcessingDeposit(true);

    try {
      await fetchCaptcha();

      const startTime = performance.now();
      await createDeposit(captchaData.value ?? {});

      datadogPlugin.sendCustomMetric("Duration DepositCreateP2P", {
        durationDepositCreateP2P: performance.now() - startTime,
      });

      const data = depositData.value as DepositCreateResponse;

      changeResponse({ ...response.value, depositId: data.depositId });

      if (data.isFieldsRequired) {
        setFields([...fields.value, ...mapFields(mapAdditionalFields((data.fields ?? []) as AdditionalField[]))]);
        return;
      }

      const bin = await getBankBin(data.card);

      if (bin) {
        await handleFetchBank({ bin });
      }

      changeResponse(data);

      await router.push({ name: routeNamesV2.depositPeerToPeerInvoicePage });
    } catch (e) {
      handleError(e as RequestError);
    } finally {
      changeProcessingDeposit(false);
    }
  };

  return {
    bank,
    depositCompleteData,
    response,
    isCancellingDeposit,
    isCompletingDeposit,
    isCheckingDeposit,
    isCreatingDeposit,
    isDepositCancelled,
    isDepositExists,
    isFetchingBank,
    isFetchingCaptcha,
    isGuaranteed,
    isProcessingDeposit,
    hasCancelButton,
    hasSupportButton,
    changeBank,
    changeResponse,
    handleCancelDeposit,
    handleCheckDeposit,
    handleCompleteDeposit,
    handleCreateDeposit,
    handleFetchBank,
    handleFetchDepositStatus,
  };
});
